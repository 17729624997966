var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c("gov-heading", { attrs: { size: "l" } }, [
                _vm._v("Collection: Categories")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        On this page, you can select which taxonomies apply for each category\n        on the front page. You can also add categories using the button above.\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        You can move categories up and down. This denotes the order in which\n        the categories appear on the home page. The category at the top of the\n        list will appear on the upper-leftmost corner.\n      "
                )
              ])
            ],
            1
          ),
          _vm.auth.isSuperAdmin
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "collections-categories-create" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new category")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _vm.loading
                ? _c("ck-loader")
                : _c(
                    "gov-list",
                    { attrs: { bullet: "" } },
                    _vm._l(_vm.collections, function(collection) {
                      return _c("collection-list-item", {
                        key: collection.id,
                        attrs: {
                          collection: collection,
                          collections: _vm.collections,
                          "edit-collection-route": "collections-categories-edit"
                        },
                        on: {
                          "move-up": _vm.onMoveUp,
                          "move-down": _vm.onMoveDown
                        }
                      })
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }